import { Empty } from "antd";
import style from "./index.module.less";
const Nodata = ({
	src = Empty.PRESENTED_IMAGE_DEFAULT,
	description = "系统正在匹配数据，请稍后查看",
	fontSize = 12,
}: {
	description?: any;
	fontSize?: number;
	src?: any;
}) => {
	return (
		<Empty
			className={style.nodata}
			description={<span style={{ fontSize }}>{description}</span>}
			image={src}
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		/>
	);
};
export default Nodata;
